import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { parseHtml } from '../../../utils/common'
import handleViewport from 'react-in-viewport'

function ProcessSection(props) {
	const { forwardedRef, inViewport } = props
	const [desc, setDesc] = useState('')
	const [active, setActive] = useState(0)
	const [mobileDesc, setMobileDesc] = useState('')
	const [activeMobile, setActiveMobile] = useState(0)
	const process = props.process
	function handleMouseHover(index, hover) {
		setDesc(hover)
		setActive(index)
	}

	const handleClick = (index, hover) => {
		setMobileDesc(hover)
		setActiveMobile(index)
	}

	return (
		<>
			<section ref={forwardedRef} style={{minHeight: '600px'}} className="process-section">
				{inViewport && (
					<>
						<div className="process-inner m-none desktop-process-inner">
							<Container>
								<Row className="justify-content-center">
									<Col lg={12}>
										<div className="heading">
											<h3>{process?.short_title}</h3>
											<h2>{process?.Title}</h2>
										</div>
									</Col>
								</Row>

								<Row className="justify-content-center">
									<Col lg={12}>
										<div className="process-list">
											<ul>
												{process?.process_steps.map((pro, index) => {
													return (
														<li
															key={index}
															onMouseOver={() =>
																handleMouseHover(index, pro.hover)
															}
														>
															<span>{pro?.label}</span>
														</li>
													)
												})}
											</ul>
										</div>
									</Col>
								</Row>

								<Row className="justify-content-center">
									<Col lg={12}>
										<div className="process-text">
											{parseHtml(desc ? desc : process?.Description, true) || ''}
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="process-inner mobile-process-inner">
							<Container>
								<Row className="justify-content-center">
									<Col lg={12}>
										<div className="heading">
											<h3>{process?.short_title}</h3>
											<h2>{process?.Title}</h2>
										</div>
									</Col>
								</Row>

								<Row className="justify-content-center">
									<Col lg={12}>
										<div className="mobile-process-list">
											<ul>
												{process?.process_steps.map((pro, index) => {
													return (
														<li
															key={index}
															className={`${activeMobile === index ? 'list-active' : ''
																}`}
															onClick={() => handleClick(index, pro.hover)}
															id={`trigger-${index}`}
														>
															<div className="process-list-title">
																{pro?.label}
															</div>
															<div className="process-text">
																{parseHtml(
																	mobileDesc
																		? mobileDesc
																		: process?.Description, true
																) || ''}
															</div>
														</li>
													)
												})}
											</ul>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</>
				)}
			</section>
		</>
	)
}

const ProcessSectionViewportBlock = handleViewport(ProcessSection);

export default ProcessSectionViewportBlock
